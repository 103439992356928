











































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Watch } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { State, Action } from "vuex-class";

type Gasto = {
  descripcion: string;
  monto: number;
  horasUtiles?: string;
  id?: number;
};

@Component
export default class EventoGastos extends Vue {
  @State(({ eventoState }) => eventoState.evento.gastos) gastos!: any;
  @State(({ eventoState }) => eventoState.evento.id) id!: any;

  // @Action definirGastos!: Function;
  @Action registrarGasto!: Function;
  @Action actualizarGasto!: Function;
  @Action eliminarGasto!: Function;

  @Validate({ required }) fecha = "";
  @Validate({ required }) factura = "";
  @Validate({ required }) garantia = "";

  public dialog = false;
  public dialogDelete = false;
  public headers = [
    {
      text: "DESCRIPCIÓN",
      align: "start",
      sortable: false,
      value: "descripcion"
    },
    { text: "MONTO", value: "monto", align: "start", sortable: false },
    {
      text: "HORAS UTILES",
      value: "horasUtiles",
      align: "start",
      sortable: false
    },
    { text: "ACCIONES", value: "actions", sortable: false }
  ];
  public desserts: Array<any> = [];
  public total = 0;
  public editedIndex = -1;
  public editedItem: Gasto = {
    descripcion: "",
    monto: 0,
    horasUtiles: ""
  };
  public defaultItem: Gasto = {
    descripcion: "",
    monto: 0,
    horasUtiles: ""
  };

  public currencyFormat(number: number): string {
    return new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "CLP"
    }).format(number);
  }

  get formTitle(): string {
    return this.editedIndex === -1 ? "Agregar" : "Editar";
  }
  @Watch("dialog")
  dialogName(val: any) {
    val || this.close();
  }
  @Watch("dialogDelete")
  dialogDeleteName(val: any) {
    val || this.closeDelete();
  }

  private editItem(item: any) {
    const copyGastos = this.gastos;
    this.editedIndex = copyGastos.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  }
  private deleteItem(item: any) {
    const copyGastos = this.gastos;
    this.editedIndex = copyGastos.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }
  private deleteItemConfirm() {
    const copyGastos = this.gastos;
    copyGastos.splice(this.editedIndex, 1);
    // this.definirGastos({ gastos: copyGastos });
    this.eliminarGasto({ idEvento: this.id, idGasto: this.editedItem.id });
    this.closeDelete();
  }
  private close() {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }
  private closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }
  public save() {
    const copyGastos = this.gastos;
    if (this.editedIndex > -1) {
      Object.assign(copyGastos[this.editedIndex], this.editedItem);
      console.info(this.editedItem);
      this.actualizarGasto({
        idEvento: this.id,
        idGasto: this.editedItem.id,
        data: this.editedItem
      });
    } else {
      copyGastos.push(this.editedItem);
      console.info(this.editedItem);
      this.registrarGasto({ idEvento: this.id, data: this.editedItem });
    }
    this.close();
  }

  //HABILITAR SUBMIT
  get isDisabled(): boolean {
    return !this.fecha ? true : false;
  }

  //VALIDACIONES
  get fechaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fecha.$dirty) return errors;
    !this.$v.fecha.required && errors.push("El campo es requerido");
    return errors;
  }
  get facturaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.factura.$dirty) return errors;
    !this.$v.factura.required && errors.push("El campo es requerido");
    return errors;
  }
  get garantiaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.garantia.$dirty) return errors;
    !this.$v.garantia.required && errors.push("El campo es requerido");
    return errors;
  }
}
