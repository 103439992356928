

































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import EventoReprogramar from "@/components/Evento/EventoReprogramar.vue";
import EventoCulminar from "@/components/Evento/EventoCulminar.vue";
import EventoPrioridad from "@/components/Evento/EventoPrioridad.vue";
import EventoMantenedor from "@/components/Evento/EventoMantenedor.vue";
import EventoDescripcion from "@/components/Evento/EventoDescripcion.vue";
import EventoEmail from "@/components/Evento/EventoEmail.vue";
import EventoFactura from "@/components/Evento/EventoFactura.vue";
import EventoGastos from "@/components/Evento/EventoGastos.vue";
import EventoPdf from "@/components/Evento/EventoPdf.vue";
import EventoAdjuntos from "@/components/Evento/EventoAdjuntos.vue";
import { State, Action, Getter } from "vuex-class";

@Component({
  components: {
    EventoReprogramar,
    EventoCulminar,
    EventoPrioridad,
    EventoMantenedor,
    EventoDescripcion,
    EventoEmail,
    EventoFactura,
    EventoGastos,
    EventoPdf,
    EventoAdjuntos
  }
})
export default class Evento extends Vue {
  @State(({ eventoState }) => eventoState.evento) evento!: any;
  @State(({ appState }) => appState.condominio) condominio!: any;
  @Getter obtenerEvento!: Function;
  @Action definirEvento!: Function;
  @Action definirEstado!: Function;
  @Action actualizarEvento!: Function;
  public dialogCancelar = false;
  public dialogEliminar = false;
  public eventoId = this.$route.params.evento;

  created() {
    this.definirEvento({ evento: this.obtenerEvento(parseInt(this.eventoId)) });
    console.info(this.evento);
  }

  get definirRetraso(): any {
    const hoy = new Date().getTime();
    const fechaEstablecida = new Date(this.evento.fecha).getTime();
    const retraso = Math.floor((hoy - fechaEstablecida) / 86400000);
    if (retraso >= 0) {
      return retraso === 1 ? `${retraso} día` : `${retraso || "0"} días`;
    }
    return "0 días";
  }

  protected cancelar(): void {
    this.dialogCancelar = false;
    this.definirEstado({ estado: "Cancelado" });
    this.actualizarEvento({
      idCondo: this.condominio.id,
      idEvento: this.evento.id,
      data: this.evento
    });
  }
  protected eliminar(): void {
    this.dialogEliminar = false;
    this.definirEstado({ estado: "Eliminado" });
    this.actualizarEvento({
      idCondo: this.condominio.id,
      idEvento: this.evento.id,
      data: this.evento
    });
  }
  public obtenerEstadoColor(item: string): string {
    if (item === "Por hacer") return "orange lighten-1";
    else if (item === "Completado") return "green lighten-1";
    else return "grey lighten-1";
  }
}
