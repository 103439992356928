


























































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { State, Action, Getter } from "vuex-class";

@Component
export default class EventoFactura extends Vue {
  @State(({ eventoState }) => eventoState.evento.facturacion) facturacion!: any;
  @State(({ eventoState }) => eventoState.evento) evento!: any;
  @State(({ appState }) => appState.condominio) condominio!: any;
  @Action actualizarEvento!: Function;
  @Action definirFacturacion!: Function;
  @Action registrarFacturacion!: Function;
  @Getter obtenerGastoTotal: any;
  @Validate({ required }) fecha = "";
  @Validate({ required }) factura = "";
  @Validate({ required }) garantia = "";
  public dialogFacturacion = false;
  public isDatePicker = false;
  public esValida = false;

  //REGISTRAR NUEVO MANTENEDOR
  private crearFactura(): void {
    const facturar = {
      fecha: this.fecha,
      factura: this.factura,
      garantia: this.garantia
    };
    this.definirFacturacion({ facturacion: facturar });
    this.registrarFacturacion({ idEvento: this.evento.id, data: facturar });
    this.clearForm();
    this.dialogFacturacion = false;
  }

  public formatearFecha(fecha: string): string {
    const fechaAString = `${fecha.replaceAll("/", "-")}T00:00:00`;
    return `${new Date(fechaAString).toLocaleDateString()}`;
  }

  //VALIDAR QUE LA FECHA SELECCIONADA NO SEA INFERIOR A HOY
  // get validarFecha(): boolean {
  //   const hoy = new Date();
  //   const fechaFacturacion = new Date(`${this.fecha}T00:00:00`);
  //   return fechaFacturacion >= hoy ? true : false;
  // }

  //FORMATEAR MODENA A CLP
  public currencyFormat(number: number): string {
    if (number) {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: "CLP"
      }).format(number);
    }
    return "0";
  }

  //LIMPIAR FORM
  private clearForm(): void {
    this.$v.$reset();
    // this.fecha = "";
  }

  //HABILITAR SUBMIT
  get isDisabled(): boolean {
    return !this.fecha ? true : false;
  }

  //VALIDACIONES
  get fechaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fecha.$dirty) return errors;
    !this.$v.fecha.required && errors.push("El campo es requerido");
    return errors;
  }
  get facturaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.factura.$dirty) return errors;
    !this.$v.factura.required && errors.push("El campo es requerido");
    return errors;
  }
  get garantiaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.garantia.$dirty) return errors;
    !this.$v.garantia.required && errors.push("El campo es requerido");
    return errors;
  }
}
