

























































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Watch } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { State, Action } from "vuex-class";

@Component
export default class EventoReprogramar extends Vue {
  @State(({ eventoState }) => eventoState.evento.fecha) fecha!: string;
  @State(({ eventoState }) => eventoState.evento) evento!: any;
  @State(({ appState }) => appState.condominio) condominio!: any;
  @Action definirFecha!: Function;
  @Action actualizarEvento!: Function;
  @Validate({ required }) nuevaFecha = "";
  public dialogReprogramar = false;
  public isDatePicker = false;
  public esValida = false;
  public fechaFormateada = "";

  //ABRIR MODAL PARA REPROGRAMAR FECHA
  private abrirDialog(): void {
    this.dialogReprogramar = true;
  }

  @Watch("fecha", {
    immediate: true,
    deep: true
  })
  actualizarFecha() {
    this.fechaFormateada = new Date(
      `${this.fecha}T00:00:00`
    ).toLocaleDateString();
  }

  //REGISTRAR NUEVO MANTENEDOR
  private reprogramar(): void {
    this.definirFecha({ fecha: this.nuevaFecha });
    this.actualizarEvento({
      idCondo: this.condominio.id,
      idEvento: this.evento.id,
      data: this.evento
    });
    this.clearForm();
    this.dialogReprogramar = false;
  }

  //VALIDAR QUE LA FECHA SELECCIONADA SEA SUPERIOR A HOY
  get validarFecha(): boolean {
    const hoy = new Date();
    const reprogramacion = new Date(`${this.nuevaFecha}T00:00:00`);
    return reprogramacion >= hoy ? true : false;
  }

  //LIMPIAR FORM
  private clearForm(): void {
    this.$v.$reset();
    this.nuevaFecha = "";
  }

  //HABILITAR SUBMIT
  get isDisabled(): boolean {
    return !this.nuevaFecha ? true : false;
  }

  //VALIDACIONES DE FORMULARIO
  get nuevaFechaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nuevaFecha.$dirty) return errors;
    !this.$v.nuevaFecha.required && errors.push("La fecha es requerida");
    return errors;
  }
}
