

























































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { State, Action } from "vuex-class";

@Component
export default class EventoDescripcion extends Vue {
  @State(({ eventoState }) => eventoState.evento.notaDescripcion)
  notaDescripcion!: any;
  @State(({ eventoState }) => eventoState.evento) evento!: any;
  @State(({ appState }) => appState.condominio) condominio!: any;
  @Action definirNotaDescripcion!: Function;
  @Action actualizarEvento!: Function;
  @Validate({ required }) descripcion = "";
  public dialogDescripcion = false;

  created() {
    this.descripcion = this.notaDescripcion;
  }

  private guardarDescripcion(): void {
    this.definirNotaDescripcion({ notaDescripcion: this.descripcion });

    this.actualizarEvento({
      idCondo: this.condominio.id,
      idEvento: this.evento.id,
      data: this.evento
    });
    this.dialogDescripcion = false;
  }

  get descripcionErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.descripcion.$dirty) return errors;
    !this.$v.descripcion.required && errors.push("El campo es requerido");
    return errors;
  }
}
