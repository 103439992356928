




















































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";

@Component
export default class EventoPrioridad extends Vue {
  @State(({ appState }) => appState.prioridades) prioridades!: Array<string>;
  @State(({ eventoState }) => eventoState.evento.prioridad) prioridad!: string;
  @State(({ eventoState }) => eventoState.evento) evento!: any;
  @State(({ appState }) => appState.condominio) condominio!: any;
  @Action definirPrioridad!: Function;
  @Action actualizarEvento!: Function;
  public nuevaPrioridad = "";
  public dialogPrioridad = false;

  created() {
    this.nuevaPrioridad = this.prioridad;
  }

  private cambiarPrioridad(): void {
    this.definirPrioridad({ prioridad: this.nuevaPrioridad });
    this.actualizarEvento({
      idCondo: this.condominio.id,
      idEvento: this.evento.id,
      data: this.evento
    });
    this.dialogPrioridad = false;
  }
  public obtenerPrioridadColor(item: string): string {
    if (item === "Baja") return "prioridad-baja";
    else if (item === "Normal") return "prioridad-normal";
    else return "prioridad-alta";
  }
}
