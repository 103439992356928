









































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";
import axios from "axios";
const baseURL = process.env.VUE_APP_API;

@Component
export default class EventoAdjuntos extends Vue {
  @State(({ eventoState }) => eventoState.evento)
  evento!: any;
  @State(({ appState }) => appState.condominio)
  condominio!: any;
  @State(({ appState }) => appState.adjuntos)
  adjuntos!: any;

  @Action actualizarEvento!: Function;
  @Action obtenerAdjuntos!: Function;
  @Action registrarAdjunto!: Function;

  mounted() {
    this.obtenerAdjuntos(this.evento.id);
  }

  protected downloadLink(link): string {
    console.info(`${baseURL}${link.filename}.png`);
    return `${baseURL}static/${link.filename}.png`;
  }
  protected rules = [
    (file: File) =>
      !file || file.size < 2000000 || "El archivo no puede tener mas de 2 mb"
  ];
  public dialogAdjuntar = false;
  public file!: string | Blob;

  public selectedItem = null;

  protected setFile(file: File): void {
    this.file = file;
  }

  protected adjuntar(): void {
    this.dialogAdjuntar = false;
    this.actualizarEvento({
      idCondo: this.condominio.id,
      idEvento: this.evento.id,
      data: this.evento
    });

    const formData = new FormData();
    formData.append("file", this.file);
    console.info(formData);

    this.registrarAdjunto({
      idEvento: this.evento.id,
      data: formData
    });
    this.obtenerAdjuntos(this.evento.id);
    this.clearForm();
  }

  protected download(data: any, nombre: string): void {
    const filePath = `${baseURL}/static/${data.filename}`;
    const ext = data.mimetype.includes("image")
      ? data.mimetype.replace("image/", ".")
      : ".pdf";

    axios({
      url: filePath,
      method: "GET",
      responseType: "blob"
    }).then(response => {
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `${nombre}${ext}`);
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  }

  //LIMPIAR FORM
  protected clearForm(): void {
    this.file = "";
  }

  //HABILITAR SUBMIT
  get isDisabled(): boolean {
    return !this.file ? true : false;
  }
}
