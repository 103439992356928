









































































/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import { State, Action } from "vuex-class";

type Mantenedor = {
  id?: number;
  email?: string;
  telefono?: string;
  empresa?: string;
}

@Component
export default class EventoMantenedor extends Vue {
  @State(({ appState }) => appState.mantenedores) mantenedores!: Array<any>;
  @State(({ eventoState }) => eventoState.evento.mantenedor) mantenedor!: any;
  @State(({ eventoState }) => eventoState.evento) evento!: any;
  @State(({ appState }) => appState.condominio) condominio!: any;
  @Action definirMantenedor!: Function;
  @Action actualizarEvento!: Function;
  @Action definirMantenedorId!: Function;
  public mantenedorActual: Mantenedor = {};
  public dialogMantenedor = false;

  created() {
    this.mantenedorActual = this.mantenedor;
  }

  private cambiarMantenedor(): void {
    this.definirMantenedor({ mantenedor: this.mantenedorActual });
    this.definirMantenedorId({ mantenedor_id: this.mantenedorActual.id });
    this.actualizarEvento({
      idCondo: this.condominio.id,
      idEvento: this.evento.id,
      data: this.evento
    });
    this.dialogMantenedor = false;
  }
}
