

















































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required, email, minLength } from "vuelidate/lib/validators";
import { State, Action } from "vuex-class";

@Component
export default class EventoEmail extends Vue {
  @State(({ eventoState }) => eventoState.evento.emails) emails!: any;
  @State(({ eventoState }) => eventoState.evento.id) id!: any;
  @Action definirEmails!: Function;
  @Action registrarEmail!: Function;
  @Action eliminarEmail!: Function;

  @Validate({ required, email }) email = "";
  @Validate({ required, minLength: minLength(5) }) nombre = "";
  @Validate({ required, email }) emailEditado = "";
  @Validate({ required, minLength: minLength(5) }) nombreEditado = "";
  public dialogEmail = false;
  public dialogEditarEmail = false;
  public idx = 0;

  private agregarEmail(): void {
    const copyEmails = this.emails;
    copyEmails.push({
      nombre: this.nombre,
      email: this.email
    });
    const email = {
      nombre: this.nombre,
      email: this.email
    };
    this.definirEmails({ emails: copyEmails });
    this.registrarEmail({ idEvento: this.id, data: email });
    this.dialogEmail = false;
    this.clearForm();
  }

  private editarEmail(email: any, index: number): void {
    this.dialogEditarEmail = true;
    this.id = email.id;
    this.emailEditado = email.email;
    this.nombreEditado = email.nombre;
    this.idx = index;
  }

  private guardarEmailEditado(): void {
    const emailEditado = {
      id: this.id,
      email: this.emailEditado,
      nombre: this.nombreEditado
    };
    const copyEmails = this.emails;
    copyEmails.splice(this.idx, 1, emailEditado);
    this.definirEmails({ emails: copyEmails });
    this.dialogEditarEmail = false;
  }
  private removerEmail(idEmail: number, idx: number): void {
    const copyEmails = this.emails;
    copyEmails.splice(idx, 1);
    this.definirEmails({ emails: copyEmails });
    console.info(idx, idEmail);
    this.eliminarEmail({ idEvento: this.id, idEmail: idEmail });
  }

  private clearForm(): void {
    this.$v.$reset();
    this.email = "";
    this.nombre = "";
  }

  get isDisabled(): boolean {
    return !this.email || !this.nombre ? true : false;
  }

  get isEditadoDisabled(): boolean {
    return !this.emailEditado || !this.nombreEditado ? true : false;
  }

  //VALIDACIONES
  get emailErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.required && errors.push("El campo es requerido");
    !this.$v.email.email && errors.push("El email debe ser valido");
    return errors;
  }

  get nombreErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombre.$dirty) return errors;
    !this.$v.nombre.required && errors.push("El campo es requerido");
    !this.$v.nombre.minLength && errors.push("Debe contener minimo 8 digitos");
    return errors;
  }

  get emailEditadoErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.emailEditado.$dirty) return errors;
    !this.$v.emailEditado.required && errors.push("El campo es requerido");
    !this.$v.emailEditado.email && errors.push("El email debe ser valido");
    return errors;
  }

  get nombreEditadoErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nombreEditado.$dirty) return errors;
    !this.$v.nombreEditado.required && errors.push("El campo es requerido");
    !this.$v.nombreEditado.minLength &&
      errors.push("Debe contener minimo 8 digitos");
    return errors;
  }
}
