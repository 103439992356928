
















































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue, Watch } from "vue-property-decorator";
import { Validate } from "vuelidate-property-decorators";
import { required } from "vuelidate/lib/validators";
import { State, Action } from "vuex-class";

@Component
export default class EventoCulminar extends Vue {
  @State(({ eventoState }) => eventoState.evento.culminacion)
  culminacion!: string;
  @State(({ eventoState }) => eventoState.evento)
  evento!: any;
  @State(({ eventoState }) => eventoState.evento.estado)
  @State(({ appState }) => appState.condominio)
  condominio!: any;

  @Action definirCulminacion!: Function;
  @Action definirNotaCulminacion!: Function;
  @Action actualizarEvento!: Function;
  @Action definirEstado!: Function;
  @Validate({ required }) fecha = "";
  @Validate({ required }) nota = "";
  protected rules = [
    (file: File) =>
      !file || file.size < 2000000 || "El archivo no puede tener mas de 2 mb"
  ];
  public dialogCulminar = false;
  public isDatePicker = false;
  public esValida = false;
  public fechaFormateada = "";
  public file: File | undefined;

  @Watch("culminacion", {
    immediate: true,
    deep: true
  })
  actualizarFecha() {
    this.culminacion
      ? (this.fechaFormateada = new Date(
          `${this.culminacion}T00:00:00`
        ).toLocaleDateString())
      : "";
  }

  //REGISTRAR NUEVO MANTENEDOR
  protected culminar(): void {
    this.definirCulminacion({
      culminacion: this.fecha
    });
    this.definirNotaCulminacion({ notaCulminacion: this.nota });
    this.definirEstado({ estado: "Completado" });
    this.actualizarEvento({
      idCondo: this.condominio.id,
      idEvento: this.evento.id,
      data: this.evento
    });
    this.clearForm();
    this.dialogCulminar = false;
  }

  protected setFile(file: File): void {
    console.info(file[0]);
    this.file = file;
  }

  //VALIDAR QUE LA FECHA SELECCIONADA NO SEA INFERIOR A HOY
  // get validarFecha(): boolean {
  //   const hoy = new Date();
  //   const culminar = new Date(`${this.fecha}T00:00:00`);
  //   return culminar >= hoy ? true : false;
  // }

  //LIMPIAR FORM
  protected clearForm(): void {
    this.$v.$reset();
    this.fecha = "";
    this.nota = "";
  }

  //HABILITAR SUBMIT
  get isDisabled(): boolean {
    return !this.fecha ? true : false;
  }

  //VALIDACIONES
  get fechaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.fecha.$dirty) return errors;
    !this.$v.fecha.required && errors.push("El campo es requerido");
    return errors;
  }
  get notaErrors(): Array<string> {
    const errors: Array<string> = [];
    if (!this.$v.nota.$dirty) return errors;
    !this.$v.nota.required && errors.push("El campo es requerido");
    return errors;
  }
}
